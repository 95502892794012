.name {
    margin: auto;
    margin-top: 1rem;
    max-width: 200px;
    font-weight: 500;
    color: black;
}

.Employees .position {
    font-size: 18px;
}

.Supporters {
    text-align: center;
}

.Supporters img {
    margin-top: 1rem;
    margin-bottom: 1rem;
    object-fit: cover;
}

.Supporters .bw {
    filter: grayscale(100%);
}

.Employees:hover {
    cursor: pointer;
} 

.Employees {
    width: 100%;
}

.Employees img  {
    border-radius: 50%;
}