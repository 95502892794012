.footer {
    padding: 0;

    .footer-up {
        border-bottom: 1px solid #D9AB50;
        padding: 85px 0 98px;

        ul {
            padding: 0;

            li {
                list-style: none;
                margin-bottom: 14px;

                a {
                    display: flex;
                    align-items: center;
                    color: #262626;
                    font-size: 1rem;
                    font-weight: 700;

                    svg {
                        margin-right: 15px;
                    }
                }
            }
        }
    }

    .copyright {
        padding: 38px 0 40px;
        p {
            font-size: .875rem;
            line-height: 1.357;
            color: #020202;
            font-weight: 400;
            margin: 0;
        }
    }
}
