@import "src/assets/css/sass/variables";
@import "src/assets/css/sass/mixins";

.book {
  .banner {
    padding: 81px 0 96px !important;

    .banner-container {
      max-width: 100% !important;

      .title-container {
        margin-bottom: 43px !important;

        .title {
          text-shadow: 0 2px 20px rgba(0,0,0,0.19) !important;
        }
      }
    }
  }

  .book-section-3 {
    text-align: center;

    .content {
      border-radius: 10px;
      padding: 62px 0 84px;

      .logo-wrapper {
        border-radius: 8px;
        padding: 11px;
        background-color: #FFFFFF;
        width: 54px;
        height: 54px;
        margin-left: auto;
        margin-right: auto;
        box-shadow: 0 10px 10px 0 rgba(0,0,0,0.09);
        margin-bottom: 20px;

        .logo {
          width: 32px;
        }
      }

      p {
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.417;
        max-width: 620px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 25px;
        color: $header-color;
      }
    }

    &.section {
      padding-bottom: 100px !important;
    }
  }

  .section {
    padding-top: 80px!important;
    padding-bottom: 80px!important;

    &.book-section-1 {
      padding-top: 93px!important;
    }
  }
}
