@import "../../assets/css/sass/variables";
@import "../../assets/css/sass/mixins";

.header {
  background-color: white;
  z-index: 4;
  position: sticky;
  top: 0;

  .header-container {
    padding: 0 40px;
  }

  .top {
    display: flex;
    align-items: center;
    padding: 8px 0;

    @include respond-below(lg){
      justify-content: center;
    }
  }

  .hamburger {
    display: none;
    position: absolute;
    z-index: 6;
    left: 5px;
    top: 5px;
    padding: 10px;

    @include respond-below(lg){
      display: block;
    }

    &.is-active {
      .hamburger-inner {
        transform: translate3d(0, -5px, 0) rotate(-45deg)
      }
      .hamburger-inner::before {
        top: 0;
      }
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      width: 18px;
      height: 2px;
      border-radius: 0;
    }

    .hamburger-inner::before {
      top: -5px;
    }

    .hamburger-inner::after {
      top: -10px;
    }
  }

  .logo {
    display: flex;
    text-decoration: none;
    width: 48px;
    height: 48px;

    span {
      margin-left: 15px;
      font-weight: 700;
      font-size: 1.875rem;
      line-height: calc(36/30);
      letter-spacing: -0.55px;

      @include respond-below(lg){
        font-size: 1rem;
        letter-spacing: 0.3px;
        line-height: calc(20/16);
      }

    }
  }

  .menu-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 1 0;
    margin-left: 45px;

    ul {
      display: flex;
      padding: 0;
      margin-bottom: 0;

      li {
        list-style: none;
      }
    }

    .right {

      li {
        padding: 0 12px;
        font-size: 1rem;

        a {
          text-decoration: none;
          font-weight: 700;
          color: $text-color;

          &.active {
            color: $primary;
          }
        }

        &:hover {
          a {
            color: $primary;
          }
        }
      }
    }

    .left {
      display: flex;
      align-items: center;

      ul {
        a {
          display: flex;
          padding: 0 5px;
        }
      }
      .ant-btn {
        max-width: 125px;
        margin-left: 20px;
      }

      svg path#Combined-Shape:first-child {
        fill: $primary !important;
      }
    }

    @include respond-below(lg){
      position: fixed;
      z-index: 5;
      top: 64px;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: white;
      padding: 30px 15px 0;
      transform: translateX(-100%);
      transition: transform 500ms;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-left: 0px;

      .right {
        flex-direction: column;
        width: 100%;

        li {
          padding: 12px 24px;
          text-align: center;
        }
      }

      .left {
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin-top: 20px;

        ul {
          margin-bottom: 32px;
        }

        .ant-btn {
          margin: 0;
        }
      }

      &.open {
        transform: translateX(0);
      }
    }
  }
}

.NavBar .ant-menu-item a:hover {
  text-decoration: none;
}

.NavBar .ant-menu {
    line-height: 64px;
}

.NavBar .ant-menu li {
    font-size: 16px;
    font-weight: 500;
    color: black;
}

.logo-button:hover {
    border-bottom: 2px solid transparent !important;
    color: inherit !important;
}



.contact {
    float: right;
}

.padding {
    padding: 3rem;
}

.itemgroup {
    width: 600px;
}

.float-right {
    float: right;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
