.Listing p, li {
    font-size: 22px;
    font-weight: 300;
}

.Listing {
    max-width: 1000px;
    margin: auto;
}

.Listing h1 {
    font-size: 60px;
}

.Listing h2 {
    text-align: left;
}

.left {
    text-align: left;
}