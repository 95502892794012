@import "../../../assets/css/sass/variables";
@import "../../../assets/css/sass/mixins";

.home-section-5 {
    .title {
        margin-bottom: 74px !important;
    }

    .content {
        margin-bottom: 16px;

        .main {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 149px;
                margin-right: 40px;
            }

            h3 {
                margin-bottom: 12px;

                a {
                    color: $header-color;
                    font-size: 1.5rem;
                    font-weight: 700;
                    line-height: 1.2;
                }
            }

            p {
                font-size: .875rem;
                line-height: 1.357;
                font-weight: 400;
                margin-bottom: 16px;
            }

            .more-news-link {
                display: flex;
                align-items: center;
                color: $header-color;

                span {
                    font-size: .875rem;
                    font-weight: 700;
                    line-height: 1.2;
                    margin-right: 12px;
                }
            }
        }
    }
}
