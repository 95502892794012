.Careers h1 {
    font-size: 60px;
    color: black;
}

.Careers h2 {
    font-size: 30px;
    color: black;
}

.careers-blurb {
    max-width: 900px;
    margin: auto;
}

.careers-blurb a {
    font-size: 22px;
    font-weight: 700;
    color: black;
}

.careers-blurb a img {
    margin-left: 0.8rem;
}

.Careers .openings {
    max-width: 1000px;
    margin: auto;
}

.Careers .openings .ant-row {
    margin-bottom: 2rem;
}

.Careers .openings .job {
    text-align: left;
}

.job-name {
    color: black;
    margin-bottom: 0.5rem;
    font-weight: 500;
}

.job-location {
    color: black;
    font-size: 18px;
    margin: 0;
}

.job-duration {
    font-size: 16px;
    margin: 0;
}

.apply {
    text-align: right;
}
