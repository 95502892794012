.Press .title {
    margin-bottom: 3rem;
    max-width: 700px;
    margin: auto;
}

.Press .title .link-to-speaking {
    font-weight: 600;
    color: black;
}

.Press .title .link-to-speaking img {
    margin-left: 0.5rem;
}

.Press h1 {
    font-size: 60px;
    color: black;
}

.Press h2 {
    font-size: 40px;
    font-weight: 600;
    color: black;
}

.Press h3 {
    font-size: 30px;
    color: black;
}

.Press a {
    color: black;
}


.Press a p{
    font-size: 18px;
    font-weight: 500;
    margin: 0;
}

.Press .section-block {
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.press-item {
    padding-bottom: 2rem;
    max-width: 800px;
    margin: auto;
}

.press-item > p {
    font-size: 16px;
}

.press-item img {
    margin-bottom: 1rem;
}

.speaking-engagements{
    text-align: left;
    margin-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.speaking-engagements img{
    margin-bottom: 1rem;
}

.speaking-engagements .date-location p {
    margin-bottom: 0;
}

.speaking-engagements .speaking-info p {
    margin-top: 0;
     font-size: 16px;
}

.speaking-engagements .speaking-title {
    font-size: 20px;
    font-weight: 600;
    color: black;
    margin-bottom: 0.5rem;
}

.speaking-list {
    margin-top: 3rem;
}
