@import "./sass/variables";
@import "./sass/mixins";

.bg-white {
  background-color: #FFFFFF;
}

.bg-1 {
  background-color: $section-bg-color-1;
}

.bg-2 {
  background-color: $section-bg-color-2;
}

.bg-3 {
  background-color: $section-bg-color-3;
}

.section-block {
  text-align: center;
}

.pilot-info, .pilot-info h2 {
  max-width: 1300px;
  margin: 4rem auto;
  text-align: left;
}

.section {
  padding-top: 100px;
  padding-bottom: 100px;

  @include respond-below(md) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .title {
    text-align: center;
    margin-bottom: 42px;

    h2 {
      font-weight: 700;
      font-size: 2.5rem;
      margin-bottom: 17px;
      line-height: 1.2;
    }

    p {
      font-size: 1rem;
      line-height: 1.375;
      font-weight: 400;
      margin: auto;
    }
  }
}

.banner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 70px 0;
  text-align: center;

  .banner-container {
    max-width: 660px;
    padding: 0 15px;
    margin: auto;

    .title-container {
      margin-bottom: 20px;

      .title {
        font-weight: 700;
        font-size: 4.0625rem;
        line-height: 1;
        margin-bottom: 0;
        color: #FFF;

        span {
          color: $primary;
        }

        @include respond-below(sm) {
          font-size: 3rem;
        }
      }
    }

    .sub-title {
      color: #FFF;
      font-size: 1rem;
      line-height: 1.625;
      font-weight: 400;
      margin-bottom: 40px;
      max-width: 450px;
      margin-left: auto;
      margin-right: auto;
      text-shadow: 0 2px 20px rgba(0,0,0,0.19);
    }

    .banner-card {
      border-radius: 10px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 20px 0 rgba(0,0,0,0.1);
      max-width: 609px;
      margin: auto;

      .card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 33px 38px 22px;

        h3 {
          font-size: 1.5rem;
          font-weight: 700;
          color: #262626;
          margin-bottom: 10px;
          line-height: 1.2;
        }

        p {
          font-size: .875rem;
          line-height: 1.375;
          margin-bottom: 15px;
        }

        button {
          width: 100%;
        }

        &:first-child {
          border-right: 1px solid #F3F3F3;

          @include respond-below(lg) {
            border-right: none;
            border-bottom: 1px solid #F3F3F3;
          }
        }
      }

      @include respond-below(lg) {
        max-width: 450px;
      }

      @include respond-below(sm) {
        max-width: 280px;
      }
    }

    .scroll-wrapper {
      color: #FFFFFF;
      margin-top: 40px;

      span {
        font-size: 1rem;
        font-weight: 900;
      }

      .scroll {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        position: relative;
        animation: down 2s infinite;
        -webkit-animation: down 2s infinite;
        margin: auto;
        cursor: pointer;

        &::before {
          content: '';
          position: absolute;
          top: 15px;
          left: 15px;
          width: 15px;
          height: 15px;
          border-left: 2px solid #FFFFFF;
          border-bottom: 2px solid #FFFFFF;
          transform: rotate(-45deg);
        }
      }
    }
  }
}

@keyframes down {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translateY(15px);
  }
  40% {
    transform: translate(0);
  }
}

@-webkit-keyframes down {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translateY(15px);
  }
  40% {
    transform: translate(0);
  }
}
