body {
  margin: 0;
  padding: 0;
  max-width: 100vw;
  font-size: .875rem;
  font-weight: 400;
}

h2 {
  text-align: center;
}

p {
  font-size: 22px;
  font-weight: 300;
}

a:hover {
  text-decoration: underline;
}

/* @media only screen and (max-width: 1000px) {
  h1 {
    font-size: 40px;
  }
} */
