.ImageGrid img {
    filter: contrast(80%);
    object-fit: cover;
}

.ImageGrid .top-row {
    padding-bottom: 1rem;
}

.ImageGrid .left-image {
    padding-right: 0.5rem;
}

.ImageGrid .right-image {
    padding-left: 0.5rem;
}