@import "variables";
@import "mixins";

body {
  color: $text-color;
  font-family: 'Lato', sans-serif;
}

.btn-primary {
  width: 230px;
  height: 40px;
  background: $button-bg-color;
  color: #fff;
  border: 1px solid $button-bg-color;
  font-weight: 700;
  font-size: .875rem;
  border-radius: 5px;

  &:hover, &:focus {
    color: $button-bg-color;
    background: #FFFFFF;
    border-color: $button-bg-color;
  }
}

.btn-default {
  width: 230px;
  height: 40px;
  background: #fff;
  color: $button-bg-color;
  border: 1px solid $button-bg-color;
  font-weight: 700;
  font-size: .875rem;
  border-radius: 5px;

  &:hover, &:focus {
    color: #FFFFFF;
    background: $button-bg-color;
    border-color: $button-bg-color;
  }
}

a.ant-btn {
  line-height: 38px;
  padding-top: 0;
}

.hidden {
  display: none;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: .75rem;
  padding-right: .75rem;

  @include make-container(sm);
  @include make-container(md);
  @include make-container(lg);
  @include make-container(xl);
  @include make-container(xxl);
}

img {
  max-width: 100%;
  vertical-align: middle;
}

h1, h2, h3, h4, h5, h6 {
  color: $header-color;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}
