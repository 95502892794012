@import "../../../assets/css/sass/variables";
@import "../../../assets/css/sass/mixins";

.book-section-2 {
    .img-container {
        background-size: 80%;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 230px;
        }

        &.left {
            background-position: center left;
            background-image: url("../../../assets/images/book/Yellowbanner.png");
            padding-right: 100px;
        }

        &.right {
            background-position: center right;
            background-image: url("../../../assets/images/book/Yellowbanner2.png");
            padding-left: 100px;
        }

    }

    .text-container {
        max-width: 415px;
        text-align: left !important;
        margin-bottom: 0 !important;
        margin-left: 24px;

        h2 {
            text-align: left !important;
        }

        p {
            margin-bottom: 20px !important;
        }

        &.right {
            margin-left: auto;
            margin-right: 74px;
        }

        .ant-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 143px;

            svg {
                height: 18px;
                width: 18px;
                margin-right: 15px;
            }

            &:hover {
                svg path {
                    fill: $button-bg-color;
                }
            }
        }

        @include respond-below(lg) {
            text-align: center !important;
            margin: auto !important;
            padding: 80px 1rem;

            .ant-btn {
                margin: auto;
            }

            h2 {
                text-align: center !important;
            }
        }
    }

    .book-content {
        margin-bottom: 100px;

        @include respond-below(lg) {
            margin-bottom: 20px;
        }
    }
}
