.home-section-4 {
  .container {
    text-align: center;
    max-width: 664px !important;

    p {
      font-size: 1rem;
      line-height: 1.375;
      margin-bottom: 0;

      &.speech {
        font-weight: 400;
      }

      &.j-title {
        font-weight: 700;
      }
    }
  }

  &.section {
    padding-top: 93px !important;
  }
}
