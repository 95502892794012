.Pilots h1 {
     font-size: 60px;
     color: black;
 }

.pilot-info {
    text-align: left;
}

.pilot-info h2 {
    text-align: left;
}

.pilot-info h3 {
    font-size: 26px;
}