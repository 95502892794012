.sign-up {

  .message {
    margin-bottom: 25px;

    h2 {
      text-align: left;
      font-size: 1.5rem;
      line-height: 1.417;
      font-weight: 700;
      margin-bottom: 0;
    }
  }

  .form-container {

  }
}
