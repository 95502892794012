@import "../../../assets/css/sass/variables";
@import "../../../assets/css/sass/mixins";

.book-section-1 {
    .content {
        .img-wrapper {
            padding: 0 15px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 896px;
            }
        }

        .footer {
            text-align: center;
            margin-top: 38px;

            .ant-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: auto;
                margin-right: auto;

                svg {
                    height: 18px;
                    width: 18px;
                    margin-right: 15px;
                }

                &:hover {
                    svg path {
                        fill: $button-bg-color;
                    }
                }
            }

        }
    }
}
