.form {
  .input-group {
    position: relative;

    input {
      padding: 16px 135px 15px 19px;
      border-radius: 5px;
      border-color: #E9E9E9;
      height: auto;
      font-size: .875rem;
      line-height: 1.357;
      font-weight: 400;

      &::placeholder {
        color: #B1B1B1;
      }
    }

    button {
      position: absolute;
      right: 5px;
      max-width: 127px;
      top: 6px;
    }
  }

  .ant-form-explain {
    margin-top: 5px;
    font-size: 12px;
  }

  .form-result {
    margin-top:1rem;
  }

  .spinner {
    text-align: left;
    margin-top:2rem;
  }

  .error {
    color: red;
  }

  .success {
    color: green;
  }

  .ant-form-item-label {
    display: none;
  }
}
