@import "../../../assets/css/sass/mixins";

.home-section-3 {
    .content {
        .img-wrapper {
            padding: 11px 15px 37px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 907px;
            }
        }
    }

    .footer {
        .footer-card-wrapper {
            text-align: center;

            .card {
                display: inline-block;
                text-align: center;
                padding: 0 75px;

                h2 {
                    font-size: 1.5rem;
                    margin-bottom: 28px;
                }

                &:first-child {
                    @include respond-above(lg) {
                        border-right: 1px solid #E6E6E6;
                    }
                }

                @include respond-below(lg) {
                    padding: 0 30px;
                    h2 {
                        margin-bottom: 12px;
                    }
                    &:first-child {
                        margin-bottom: 32px;
                    }
                }
            }
        }
    }
}
