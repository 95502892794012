.Team h1 {
    margin-top: 4rem;
    font-size: 60px;
    color: black;
}

.Team .section-block h2 {
    font-size: 40px;
    font-weight: 600;
}

.team-modal .position {
    font-size: 14px;
}

.team-modal .name {
    font-weight: 500;
    font-size: 22px;
    margin-top:1rem;
    margin-bottom: 0;
    color: black;
}

.team-modal .employee-content {
    font-size: 16px;
}

.section-title {
    margin-bottom: 4rem;
}

.rightAlign {
    padding-left: 4rem;
}

.bottomAlign {
    padding-top: 2rem;
}

.leftAlign {
    text-align: left;
}

.centered {
    text-align: center;
}
