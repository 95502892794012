@import "../../../assets/css/sass/variables";
@import "../../../assets/css/sass/mixins";

.home-section-1 {
  margin-bottom: 54px;

  .title {
    p {
      max-width: 436px;
    }
  }

  .content {
    .video-container {
      text-align: center;
      position: relative;
      max-width: 830px;
      margin: auto;

      .ribbon-img-wrapper {
        position: absolute;
        width: calc(100% + 120px);
        bottom: -54px;
        left: -60px;

        @include respond-below(lg) {
          width: calc(100% + 80px);
          left: -40px;
        }

        @include respond-below(md) {
          bottom: -28px;
        }
      }

      .youtube-container {
        position: relative;
        padding-top: 56.25%;
        margin: auto;
        background: #000;
        border-radius: 8px;

        .youtube-player {
          position: absolute;
          top: 0;
          left: 0;

          iframe {
            border-radius: 8px;
          }

          .react-player__preview {
            border-radius: 8px;

            .react-player__shadow {
              width: 88px !important;
              height: 88px !important;
              background: $button-bg-color !important;

              .react-player__play-icon {
                position: relative;
                background-color: #fff;
                text-align: left;
                margin-left: -9px !important;
                margin-bottom: 12px !important;
                border: none !important;
              }

              .react-player__play-icon:before,
              .react-player__play-icon:after {
                content: '';
                position: absolute;
                background-color: inherit;
              }

              .react-player__play-icon,
              .react-player__play-icon:before,
              .react-player__play-icon:after {
                width:  2em;
                height: 2em;
                border-top-right-radius: 30%;
              }

              .react-player__play-icon {
                transform: rotate(-90deg) skewX(-30deg) scale(1,.866);
              }

              .react-player__play-icon.right {
                transform: rotate(-30deg) skewX(-30deg) scale(1,.866);
              }

              .react-player__play-icon:before {
                transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
              }

              .react-player__play-icon:after {
                transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
              }
            }
          }
        }
      }

      .thumbnail-container {
        position: relative;

        img {
          &:hover {
            cursor: pointer;

            ~.btn-play {
              background: #FFFFFF;

              svg path {
                fill: $button-bg-color;
              }
            }
          }
        }

        .btn-play {
          position: absolute;
          background: $button-bg-color;
          width: 88px;
          height: 88px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          right: calc(50% - 44px);
          top: calc(50% - 44px);
          border: 1px solid $button-bg-color;

          svg {
            margin-left: 6px;
            margin-top: 2px;
          }

          &:hover {
            background: #FFFFFF;

            svg path {
              fill: $button-bg-color;
            }
          }
        }
      }
    }

    @include respond-below(md) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}
